import validate from "/home/runner/work/divine/divine/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45auth_45global from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/middleware/1.auth.global.ts";
import _2_45i18n_45global from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/middleware/2.i18n.global.ts";
import manifest_45route_45rule from "/home/runner/work/divine/divine/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45auth_45global,
  _2_45i18n_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "load-search-index": () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/middleware/load-search-index.ts")
}