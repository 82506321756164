import { default as _91_46_46_46slug_93VRaOYQt4IsMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/[...slug].vue?macro=true";
import { default as _404rc0TFhBZbCMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/404.vue?macro=true";
import { default as change_45password_45_91uuid_93powRWruHGCMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/change-password-[uuid].vue?macro=true";
import { default as forgot_45passwordSKiZPfIOGAMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/forgot-password.vue?macro=true";
import { default as helpa1h8CtQAtPMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/help.vue?macro=true";
import { default as indexJ4RC1iLM1kMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/index.vue?macro=true";
import { default as loginb37KLSkYItMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/login.vue?macro=true";
import { default as not_45authorizedbHeDnv2pgAMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/not-authorized.vue?macro=true";
import { default as instrumentsJEUZ4CTDWgMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/instruments.vue?macro=true";
import { default as packsT2oY7gnLWUMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/packs.vue?macro=true";
import { default as tagsfHF1LNUxYvMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/tags.vue?macro=true";
import { default as usersGXaEHAZPGsMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/users.vue?macro=true";
import { default as videosNuySuAYoi7Meta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/videos.vue?macro=true";
import { default as _91id_93t1ppA7vhWwMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/creators/[id].vue?macro=true";
import { default as homeqrEMYj8RpMMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/home.vue?macro=true";
import { default as _91id_93ateQScjmurMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/instruments/[id].vue?macro=true";
import { default as listGgWHm1MD8pMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/instruments/list.vue?macro=true";
import { default as _91id_930tWtfty5lUMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/packs/[id].vue?macro=true";
import { default as listm8fqnZVL1uMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/packs/list.vue?macro=true";
import { default as searchdYMYM5xUdCMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/search.vue?macro=true";
import { default as indexbcKaxytcVvMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/index.vue?macro=true";
import { default as cartAzKzVi4F01Meta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/shop/cart.vue?macro=true";
import { default as checkoutKeOzCkuiFkMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/shop/checkout.vue?macro=true";
import { default as payment_45returnMClcgAFacCMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/shop/payment-return.vue?macro=true";
import { default as paymentGCkd5WxUYMMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/shop/payment.vue?macro=true";
import { default as _91tab_93Ov7l4wOlYoMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/account-settings/[tab].vue?macro=true";
import { default as favoritesobAkOQ2h6IMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/favorites.vue?macro=true";
import { default as learningJilkO12e0vMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/learning.vue?macro=true";
import { default as purchasesWfkKw6m5FfMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/purchases.vue?macro=true";
import { default as salesUVlxOYHqaAMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/sales.vue?macro=true";
import { default as email_45_91uuid_93WgnxvGY9WmMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/verify/email-[uuid].vue?macro=true";
import { default as user_45_91uuid_93mGWzGbAdFiMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/verify/user-[uuid].vue?macro=true";
import { default as register6RKAVvINEVMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/register.vue?macro=true";
import { default as about1xTssgF75RMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/about.vue?macro=true";
import { default as _91id_93M68X7gAxUkMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/catalog/creators/[id].vue?macro=true";
import { default as _91id_93oPPCNYYJseMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/catalog/instruments/[id].vue?macro=true";
import { default as _91id_93lUphcui1GCMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/catalog/packs/[id].vue?macro=true";
import { default as search9Ymr91rdIBMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/catalog/search.vue?macro=true";
import { default as contactyWEmRtajBtMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/contact.vue?macro=true";
import { default as desktop_45downloadkkEG0m3aaiMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/desktop-download.vue?macro=true";
import { default as cartuCqHWy2Ug2Meta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/shop/cart.vue?macro=true";
import { default as _91id_93OVH3KFusx8Meta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/youtubers/channels/[id].vue?macro=true";
import { default as indexTFlJDYEddjMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/youtubers/index.vue?macro=true";
import { default as content_45navigationQHzqmaJEkGMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/test/content-navigation.vue?macro=true";
import { default as content_45query_45all6sLgdYLURMMeta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/test/content-query-all.vue?macro=true";
import { default as content_45query_45playground6t91IoPOx7Meta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/test/content-query-playground.vue?macro=true";
import { default as content_45searchG1ymhF2Y28Meta } from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/test/content-search.vue?macro=true";
import { default as component_45stubQubf0yWtVzMeta } from "/home/runner/work/divine/divine/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubQubf0yWtVz } from "/home/runner/work/divine/divine/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93VRaOYQt4IsMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/[...slug].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    meta: _91_46_46_46slug_93VRaOYQt4IsMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/[...slug].vue")
  },
  {
    name: "404___en",
    path: "/404",
    meta: _404rc0TFhBZbCMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/404.vue")
  },
  {
    name: "404___fr",
    path: "/fr/404",
    meta: _404rc0TFhBZbCMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/404.vue")
  },
  {
    name: "change-password-uuid___en",
    path: "/change-password-:uuid()",
    meta: change_45password_45_91uuid_93powRWruHGCMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/change-password-[uuid].vue")
  },
  {
    name: "change-password-uuid___fr",
    path: "/fr/change-password-:uuid()",
    meta: change_45password_45_91uuid_93powRWruHGCMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/change-password-[uuid].vue")
  },
  {
    name: "forgot-password___en",
    path: "/forgot-password",
    meta: forgot_45passwordSKiZPfIOGAMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___fr",
    path: "/fr/forgot-password",
    meta: forgot_45passwordSKiZPfIOGAMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/forgot-password.vue")
  },
  {
    name: "help___en",
    path: "/help",
    meta: helpa1h8CtQAtPMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/help.vue")
  },
  {
    name: "help___fr",
    path: "/fr/help",
    meta: helpa1h8CtQAtPMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/help.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexJ4RC1iLM1kMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexJ4RC1iLM1kMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginb37KLSkYItMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    meta: loginb37KLSkYItMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/login.vue")
  },
  {
    name: "not-authorized___en",
    path: "/not-authorized",
    meta: not_45authorizedbHeDnv2pgAMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/not-authorized.vue")
  },
  {
    name: "not-authorized___fr",
    path: "/fr/not-authorized",
    meta: not_45authorizedbHeDnv2pgAMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/not-authorized.vue")
  },
  {
    name: "private-admin-instruments___en",
    path: "/private/admin/instruments",
    meta: instrumentsJEUZ4CTDWgMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/instruments.vue")
  },
  {
    name: "private-admin-instruments___fr",
    path: "/fr/private/admin/instruments",
    meta: instrumentsJEUZ4CTDWgMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/instruments.vue")
  },
  {
    name: "private-admin-packs___en",
    path: "/private/admin/packs",
    meta: packsT2oY7gnLWUMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/packs.vue")
  },
  {
    name: "private-admin-packs___fr",
    path: "/fr/private/admin/packs",
    meta: packsT2oY7gnLWUMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/packs.vue")
  },
  {
    name: "private-admin-tags___en",
    path: "/private/admin/tags",
    meta: tagsfHF1LNUxYvMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/tags.vue")
  },
  {
    name: "private-admin-tags___fr",
    path: "/fr/private/admin/tags",
    meta: tagsfHF1LNUxYvMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/tags.vue")
  },
  {
    name: "private-admin-users___en",
    path: "/private/admin/users",
    meta: usersGXaEHAZPGsMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/users.vue")
  },
  {
    name: "private-admin-users___fr",
    path: "/fr/private/admin/users",
    meta: usersGXaEHAZPGsMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/users.vue")
  },
  {
    name: "private-admin-videos___en",
    path: "/private/admin/videos",
    meta: videosNuySuAYoi7Meta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/videos.vue")
  },
  {
    name: "private-admin-videos___fr",
    path: "/fr/private/admin/videos",
    meta: videosNuySuAYoi7Meta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/admin/videos.vue")
  },
  {
    name: "private-catalog-creators-id___en",
    path: "/private/catalog/creators/:id()",
    meta: _91id_93t1ppA7vhWwMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/creators/[id].vue")
  },
  {
    name: "private-catalog-creators-id___fr",
    path: "/fr/private/catalog/creators/:id()",
    meta: _91id_93t1ppA7vhWwMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/creators/[id].vue")
  },
  {
    name: "private-catalog-home___en",
    path: "/private/catalog/home",
    meta: homeqrEMYj8RpMMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/home.vue")
  },
  {
    name: "private-catalog-home___fr",
    path: "/fr/private/catalog/home",
    meta: homeqrEMYj8RpMMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/home.vue")
  },
  {
    name: "private-catalog-instruments-id___en",
    path: "/private/catalog/instruments/:id()",
    meta: _91id_93ateQScjmurMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/instruments/[id].vue")
  },
  {
    name: "private-catalog-instruments-id___fr",
    path: "/fr/private/catalog/instruments/:id()",
    meta: _91id_93ateQScjmurMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/instruments/[id].vue")
  },
  {
    name: "private-catalog-instruments-list___en",
    path: "/private/catalog/instruments/list",
    meta: listGgWHm1MD8pMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/instruments/list.vue")
  },
  {
    name: "private-catalog-instruments-list___fr",
    path: "/fr/private/catalog/instruments/list",
    meta: listGgWHm1MD8pMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/instruments/list.vue")
  },
  {
    name: "private-catalog-packs-id___en",
    path: "/private/catalog/packs/:id()",
    meta: _91id_930tWtfty5lUMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/packs/[id].vue")
  },
  {
    name: "private-catalog-packs-id___fr",
    path: "/fr/private/catalog/packs/:id()",
    meta: _91id_930tWtfty5lUMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/packs/[id].vue")
  },
  {
    name: "private-catalog-packs-list___en",
    path: "/private/catalog/packs/list",
    meta: listm8fqnZVL1uMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/packs/list.vue")
  },
  {
    name: "private-catalog-packs-list___fr",
    path: "/fr/private/catalog/packs/list",
    meta: listm8fqnZVL1uMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/packs/list.vue")
  },
  {
    name: "private-catalog-search___en",
    path: "/private/catalog/search",
    meta: searchdYMYM5xUdCMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/search.vue")
  },
  {
    name: "private-catalog-search___fr",
    path: "/fr/private/catalog/search",
    meta: searchdYMYM5xUdCMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/catalog/search.vue")
  },
  {
    name: "private___en",
    path: "/private",
    meta: indexbcKaxytcVvMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/index.vue")
  },
  {
    name: "private___fr",
    path: "/fr/private",
    meta: indexbcKaxytcVvMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/index.vue")
  },
  {
    name: "private-shop-cart___en",
    path: "/private/shop/cart",
    meta: cartAzKzVi4F01Meta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/shop/cart.vue")
  },
  {
    name: "private-shop-cart___fr",
    path: "/fr/private/shop/cart",
    meta: cartAzKzVi4F01Meta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/shop/cart.vue")
  },
  {
    name: "private-shop-checkout___en",
    path: "/private/shop/checkout",
    meta: checkoutKeOzCkuiFkMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/shop/checkout.vue")
  },
  {
    name: "private-shop-checkout___fr",
    path: "/fr/private/shop/checkout",
    meta: checkoutKeOzCkuiFkMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/shop/checkout.vue")
  },
  {
    name: "private-shop-payment-return___en",
    path: "/private/shop/payment-return",
    meta: payment_45returnMClcgAFacCMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/shop/payment-return.vue")
  },
  {
    name: "private-shop-payment-return___fr",
    path: "/fr/private/shop/payment-return",
    meta: payment_45returnMClcgAFacCMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/shop/payment-return.vue")
  },
  {
    name: "private-shop-payment___en",
    path: "/private/shop/payment",
    meta: paymentGCkd5WxUYMMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/shop/payment.vue")
  },
  {
    name: "private-shop-payment___fr",
    path: "/fr/private/shop/payment",
    meta: paymentGCkd5WxUYMMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/shop/payment.vue")
  },
  {
    name: "private-user-account-settings-tab___en",
    path: "/private/user/account-settings/:tab()",
    meta: _91tab_93Ov7l4wOlYoMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/account-settings/[tab].vue")
  },
  {
    name: "private-user-account-settings-tab___fr",
    path: "/fr/private/user/account-settings/:tab()",
    meta: _91tab_93Ov7l4wOlYoMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/account-settings/[tab].vue")
  },
  {
    name: "private-user-favorites___en",
    path: "/private/user/favorites",
    meta: favoritesobAkOQ2h6IMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/favorites.vue")
  },
  {
    name: "private-user-favorites___fr",
    path: "/fr/private/user/favorites",
    meta: favoritesobAkOQ2h6IMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/favorites.vue")
  },
  {
    name: "private-user-learning___en",
    path: "/private/user/learning",
    meta: learningJilkO12e0vMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/learning.vue")
  },
  {
    name: "private-user-learning___fr",
    path: "/fr/private/user/learning",
    meta: learningJilkO12e0vMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/learning.vue")
  },
  {
    name: "private-user-purchases___en",
    path: "/private/user/purchases",
    meta: purchasesWfkKw6m5FfMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/purchases.vue")
  },
  {
    name: "private-user-purchases___fr",
    path: "/fr/private/user/purchases",
    meta: purchasesWfkKw6m5FfMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/purchases.vue")
  },
  {
    name: "private-user-sales___en",
    path: "/private/user/sales",
    meta: salesUVlxOYHqaAMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/sales.vue")
  },
  {
    name: "private-user-sales___fr",
    path: "/fr/private/user/sales",
    meta: salesUVlxOYHqaAMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/user/sales.vue")
  },
  {
    name: "private-verify-email-uuid___en",
    path: "/private/verify/email-:uuid()",
    meta: email_45_91uuid_93WgnxvGY9WmMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/verify/email-[uuid].vue")
  },
  {
    name: "private-verify-email-uuid___fr",
    path: "/fr/private/verify/email-:uuid()",
    meta: email_45_91uuid_93WgnxvGY9WmMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/verify/email-[uuid].vue")
  },
  {
    name: "private-verify-user-uuid___en",
    path: "/private/verify/user-:uuid()",
    meta: user_45_91uuid_93mGWzGbAdFiMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/verify/user-[uuid].vue")
  },
  {
    name: "private-verify-user-uuid___fr",
    path: "/fr/private/verify/user-:uuid()",
    meta: user_45_91uuid_93mGWzGbAdFiMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/private/verify/user-[uuid].vue")
  },
  {
    name: "register___en",
    path: "/register",
    meta: register6RKAVvINEVMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/register.vue")
  },
  {
    name: "register___fr",
    path: "/fr/register",
    meta: register6RKAVvINEVMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/register.vue")
  },
  {
    name: "site-about___en",
    path: "/site/about",
    meta: about1xTssgF75RMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/about.vue")
  },
  {
    name: "site-about___fr",
    path: "/fr/site/about",
    meta: about1xTssgF75RMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/about.vue")
  },
  {
    name: "site-catalog-creators-id___en",
    path: "/site/catalog/creators/:id()",
    meta: _91id_93M68X7gAxUkMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/catalog/creators/[id].vue")
  },
  {
    name: "site-catalog-creators-id___fr",
    path: "/fr/site/catalog/creators/:id()",
    meta: _91id_93M68X7gAxUkMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/catalog/creators/[id].vue")
  },
  {
    name: "site-catalog-instruments-id___en",
    path: "/site/catalog/instruments/:id()",
    meta: _91id_93oPPCNYYJseMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/catalog/instruments/[id].vue")
  },
  {
    name: "site-catalog-instruments-id___fr",
    path: "/fr/site/catalog/instruments/:id()",
    meta: _91id_93oPPCNYYJseMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/catalog/instruments/[id].vue")
  },
  {
    name: "site-catalog-packs-id___en",
    path: "/site/catalog/packs/:id()",
    meta: _91id_93lUphcui1GCMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/catalog/packs/[id].vue")
  },
  {
    name: "site-catalog-packs-id___fr",
    path: "/fr/site/catalog/packs/:id()",
    meta: _91id_93lUphcui1GCMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/catalog/packs/[id].vue")
  },
  {
    name: "site-catalog-search___en",
    path: "/site/catalog/search",
    meta: search9Ymr91rdIBMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/catalog/search.vue")
  },
  {
    name: "site-catalog-search___fr",
    path: "/fr/site/catalog/search",
    meta: search9Ymr91rdIBMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/catalog/search.vue")
  },
  {
    name: "site-contact___en",
    path: "/site/contact",
    meta: contactyWEmRtajBtMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/contact.vue")
  },
  {
    name: "site-contact___fr",
    path: "/fr/site/contact",
    meta: contactyWEmRtajBtMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/contact.vue")
  },
  {
    name: "site-desktop-download___en",
    path: "/site/desktop-download",
    meta: desktop_45downloadkkEG0m3aaiMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/desktop-download.vue")
  },
  {
    name: "site-desktop-download___fr",
    path: "/fr/site/desktop-download",
    meta: desktop_45downloadkkEG0m3aaiMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/desktop-download.vue")
  },
  {
    name: "site-shop-cart___en",
    path: "/site/shop/cart",
    meta: cartuCqHWy2Ug2Meta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/shop/cart.vue")
  },
  {
    name: "site-shop-cart___fr",
    path: "/fr/site/shop/cart",
    meta: cartuCqHWy2Ug2Meta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/shop/cart.vue")
  },
  {
    name: "site-youtubers-channels-id___en",
    path: "/site/youtubers/channels/:id()",
    meta: _91id_93OVH3KFusx8Meta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/youtubers/channels/[id].vue")
  },
  {
    name: "site-youtubers-channels-id___fr",
    path: "/fr/site/youtubers/channels/:id()",
    meta: _91id_93OVH3KFusx8Meta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/youtubers/channels/[id].vue")
  },
  {
    name: "site-youtubers___en",
    path: "/site/youtubers",
    meta: indexTFlJDYEddjMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/youtubers/index.vue")
  },
  {
    name: "site-youtubers___fr",
    path: "/fr/site/youtubers",
    meta: indexTFlJDYEddjMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/site/youtubers/index.vue")
  },
  {
    name: "test-content-navigation___en",
    path: "/test/content-navigation",
    meta: content_45navigationQHzqmaJEkGMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/test/content-navigation.vue")
  },
  {
    name: "test-content-navigation___fr",
    path: "/fr/test/content-navigation",
    meta: content_45navigationQHzqmaJEkGMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/test/content-navigation.vue")
  },
  {
    name: "test-content-query-all___en",
    path: "/test/content-query-all",
    meta: content_45query_45all6sLgdYLURMMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/test/content-query-all.vue")
  },
  {
    name: "test-content-query-all___fr",
    path: "/fr/test/content-query-all",
    meta: content_45query_45all6sLgdYLURMMeta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/test/content-query-all.vue")
  },
  {
    name: "test-content-query-playground___en",
    path: "/test/content-query-playground",
    meta: content_45query_45playground6t91IoPOx7Meta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/test/content-query-playground.vue")
  },
  {
    name: "test-content-query-playground___fr",
    path: "/fr/test/content-query-playground",
    meta: content_45query_45playground6t91IoPOx7Meta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/test/content-query-playground.vue")
  },
  {
    name: "test-content-search___en",
    path: "/test/content-search",
    meta: content_45searchG1ymhF2Y28Meta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/test/content-search.vue")
  },
  {
    name: "test-content-search___fr",
    path: "/fr/test/content-search",
    meta: content_45searchG1ymhF2Y28Meta || {},
    component: () => import("/home/runner/work/divine/divine/packages/dvn-nuxt-platform/pages/test/content-search.vue")
  },
  {
    name: component_45stubQubf0yWtVzMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubQubf0yWtVz
  },
  {
    name: component_45stubQubf0yWtVzMeta?.name,
    path: "/en-pages-sitemap.xml",
    component: component_45stubQubf0yWtVz
  },
  {
    name: component_45stubQubf0yWtVzMeta?.name,
    path: "/fr-pages-sitemap.xml",
    component: component_45stubQubf0yWtVz
  },
  {
    name: component_45stubQubf0yWtVzMeta?.name,
    path: "/en-catalog-sitemap.xml",
    component: component_45stubQubf0yWtVz
  },
  {
    name: component_45stubQubf0yWtVzMeta?.name,
    path: "/en-channels-sitemap.xml",
    component: component_45stubQubf0yWtVz
  }
]