import revive_payload_client_YTGlmQWWT5 from "/home/runner/work/divine/divine/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/runner/work/divine/divine/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/runner/work/divine/divine/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fWYXZjzQhM from "/home/runner/work/divine/divine/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_VTarsGAzgb from "/home/runner/work/divine/divine/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/home/runner/work/divine/divine/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/home/runner/work/divine/divine/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/home/runner/work/divine/divine/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/home/runner/work/divine/divine/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/runner/work/divine/divine/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Nx7BZebwQV from "/home/runner/work/divine/divine/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_lCxu0ZmxIx from "/home/runner/work/divine/divine/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5C7QFp7tsq from "/home/runner/work/divine/divine/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import index_0BaboSgNMq from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/plugins/layouts/index.ts";
import index_kyvreQaQna from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/plugins/vuetify/index.ts";
import casl_QTBkbB6FCs from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/plugins/casl.ts";
import electron_ipc_api_MSICFOjjP3 from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/plugins/electron-ipc-api.ts";
import nuxt_error_hooks_b1Mep9do5h from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/plugins/nuxt-error-hooks.ts";
import nuxt_page_hooks_ISYXsuIs9F from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/plugins/nuxt-page-hooks.ts";
import perfect_scrollbar_eDszbQJYAy from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/plugins/perfect-scrollbar.ts";
import trpc_client_sNkZYQPPl5 from "/home/runner/work/divine/divine/packages/dvn-nuxt-platform/plugins/trpc-client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_fWYXZjzQhM,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_client_Nx7BZebwQV,
  switch_locale_path_ssr_lCxu0ZmxIx,
  i18n_5C7QFp7tsq,
  index_0BaboSgNMq,
  index_kyvreQaQna,
  casl_QTBkbB6FCs,
  electron_ipc_api_MSICFOjjP3,
  nuxt_error_hooks_b1Mep9do5h,
  nuxt_page_hooks_ISYXsuIs9F,
  perfect_scrollbar_eDszbQJYAy,
  trpc_client_sNkZYQPPl5
]